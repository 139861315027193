var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-wrap w-100 px-sm-4",
          staticStyle: {
            "background-color": "var(--spainhn-rojo-corporativo)",
          },
        },
        [
          _c("div", { staticClass: "py-2 px-2 ms-auto" }, [
            _c(
              "a",
              {
                staticClass: "link-light",
                attrs: { href: "/clientes/reservas" },
              },
              [
                _c("small", [
                  _vm._v(_vm._s(_vm.$t("default.CLIENTES_RESERVAS"))),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "py-2 border-start px-2" }, [
            _c(
              "a",
              {
                staticClass: "link-light",
                attrs: { href: "/clientes/cambiaclave" },
              },
              [
                _c("small", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("default.CLIENTES_PASSWORD_RESET")) +
                      " "
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "py-2 border-start px-2" }, [
            _c(
              "a",
              {
                staticClass: "link-light",
                attrs: { href: "/clientes/cambiaemail" },
              },
              [
                _c("small", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("default.CLIENTES_EMAIL_CHANGE")) + " "
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "py-2 border-start px-2" }, [
            _c(
              "a",
              {
                staticClass: "link-light",
                attrs: { href: "/clientes/editar" },
              },
              [
                _c("small", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("default.CLIENTES_EDIT_PROFILE")) + " "
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "py-2 border-start px-2 me-auto" }, [
            _c(
              "a",
              {
                staticClass: "link-light",
                attrs: { href: "#" },
                on: { click: _vm.logout },
              },
              [
                _c("small", [
                  _vm._v(" " + _vm._s(_vm.$t("default.CLIENTES_LOGOUT")) + " "),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "container bg-white my-5 border py-5" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("p", { staticClass: "spainhn-subtitle-2-black" }, [
                  _vm._v(_vm._s(_vm.$t("default.CLIENTES_RESERVAS_TITLE"))),
                ]),
              ]),
              _c("div", { staticClass: "col-7 mx-auto" }, [
                _c("p", { staticClass: "spainhn-text" }, [
                  _vm._v(_vm._s(_vm.$t("default.CLIENTES_RESERVAS_ABSTRACT"))),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "col-12 col-lg-10 mx-auto" },
            [
              _c("p", { class: { "d-none": _vm.reservasEncontradas } }, [
                _vm._v("Sin información"),
              ]),
              _vm._l(_vm.reservas, function (reserva, index) {
                return _c("div", { key: index, staticClass: "p-4 border" }, [
                  _c(
                    "div",
                    { staticClass: "text-start" },
                    [
                      _c("p", { staticClass: "spainhn-subtitle" }, [
                        _vm._v(_vm._s(reserva.visitaNombre) + " "),
                        _c(
                          "span",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "text-muted",
                              attrs: {
                                icon: _vm.checkDate(reserva.fechaVisita),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        reserva.cancelado
                          ? _c(
                              "label",
                              { attrs: { clas: "label label-warning" } },
                              [_vm._v("Cancelado")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.botonCancelar(reserva)
                          ? _c(
                              "button",
                              {
                                staticClass: "btn spainhn-button btn-secondary",
                                staticStyle: {
                                  "-webkit-text-fill-color": "white",
                                },
                                attrs: {
                                  type: "button",
                                  disabled: _vm.expireDate(reserva),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.modalCancelar(reserva)
                                  },
                                },
                              },
                              [_vm._v("Cancelar Reservación")]
                            )
                          : _vm._e(),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.formatFechaVisita(reserva.fechaVisita)) +
                            " - " +
                            _vm._s(_vm.horaVisita(reserva.hora)) +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.$t("default.CODIGO_RESERVA_LABEL")) + " "
                        ),
                        _c("span", { staticClass: "spainhn-subtitle" }, [
                          _vm._v(_vm._s(reserva.localizador)),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.$t("default.NUMERO_PEDIDO_LABEL")) + " "
                        ),
                        _c("span", { staticClass: "fw-bold" }, [
                          _vm._v(_vm._s(reserva.numeroDePedido)),
                        ]),
                      ]),
                      _vm._l(reserva.descripcion, function (linea, index) {
                        return _c("div", { key: index, staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v(
                              " " +
                                _vm._s(linea.descripcion) +
                                " - " +
                                _vm._s(linea.cantidad) +
                                " "
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ])
              }),
            ],
            2
          ),
          _c("div", { staticClass: "col-12 col-lg-8 mx-auto" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap justify-content-center pt-3" },
              [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c(
                      "mdb-btn",
                      {
                        staticClass: "spainhn-button mx-auto",
                        attrs: { color: "indigo", size: "lg" },
                        on: { click: () => _vm.$router.push("/clientes") },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("default.CLIENTES_CANCEL_PASSWORD_BUTTON")
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "aria-label": "",
            "hide-header": "",
            "hide-footer": "",
          },
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "border px-2 py-4 border-warning mb-3",
              staticStyle: { "background-color": "rgb(255, 255, 204)" },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  { staticClass: "d-flex mx-auto" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "my-auto text-warning me-2",
                      attrs: { icon: "fa-circle-exclamation", size: "3x" },
                    }),
                    _c("p", { staticClass: "my-auto" }, [
                      _c("strong", { staticClass: "text-spainhn" }, [
                        _vm._v("¿Estas seguro de cancelar la reservación?"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "d-flex w-100" }, [
                _c(
                  "div",
                  { staticClass: "mx-auto" },
                  [
                    _c(
                      "mdb-btn",
                      {
                        attrs: { color: "indigo" },
                        on: {
                          click: function ($event) {
                            return _vm.cancelarReserva()
                          },
                        },
                      },
                      [_vm._v("ACEPTAR")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "aria-label": "",
            "hide-header": "",
            "hide-footer": "",
          },
          model: {
            value: _vm.successCancel,
            callback: function ($$v) {
              _vm.successCancel = $$v
            },
            expression: "successCancel",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "border px-2 py-4 border-success mb-3",
              staticStyle: { "background-color": "rgb(255, 255, 204)" },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  { staticClass: "d-flex mx-auto" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "my-auto text-success me-2",
                      attrs: { icon: "fa-circle-check", size: "3x" },
                    }),
                    _c("p", { staticClass: "my-auto" }, [
                      _c("strong", { staticClass: "text-spainhn" }, [
                        _vm._v("Se ha cancelado la reservación correctamente"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "d-flex w-100" }, [
                _c(
                  "div",
                  { staticClass: "mx-auto" },
                  [
                    _c(
                      "mdb-btn",
                      {
                        attrs: { color: "indigo" },
                        on: { click: _vm.toogleModalSuccess },
                      },
                      [_vm._v("CERRAR")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }